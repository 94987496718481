<script>
  import { orgOperators } from "$lib/services/store";
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perks from "./blocks/Perks.svelte";
  import Logo from "./blocks/Logo.svelte";
  import Badges from "./blocks/Badges.svelte";
  import ClickToAction from "./blocks/ClickToAction.svelte";
  import Bonus from "./blocks/Bonus.svelte";
  import { translate } from "$lib/common/helpers/translate";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;
  export let toplistPosition = null;

  let lang = $project.settings.lang ?? "en";
  let market = $project.settings.market;
  let primaryColor =
    operator.visuals[$project.key][$project.settings.market].primary_color;
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container">
    <Logo
      {operator}
      {primaryColor}
      {dataElement}
      {toplistPosition}
      rating={false}
      ribbon={false}
    />
    <div>
      <h2 class="sponsor-name">{operator.name}</h2>
      <p class="sponsor-description">
        {operator?.bonus?.casino[market]?.full || "No description"}
      </p>
    </div>
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    height: 80px;
    gap: 20px;
    align-items: center;
    grid-template-columns: 125px 1fr;
    grid-template-areas: "LOGO CONTENT";
    border-radius: 4px;
    background-color: #3f3f3f;
    color: #fff;
    overflow: hidden;
  }
  .sponsor-name {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .sponsor-description {
    margin: 0;
    font-size: 14px;
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }
  }
</style>
