<script>
  import { project, orgOperators } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import { page } from "$app/stores";
  import Icons from "$lib/main/components/Icons.svelte";
  import Rating from "$lib/admin/components/blocks/helpers/Rating.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import Ribbon from "$lib/admin/components/blocks/helpers/Ribbon.svelte";
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import CurrencyAmount from "$lib/admin/components/blocks/helpers/CurrencyAmount.svelte";
  import { getOperatorLogo } from "$lib/common/helpers/image.ts";
  import DefaultList from "$lib/admin/components/blocks/helpers/ToplistRow/DefaultList.svelte";
  import NoAccountList from "$lib/admin/components/blocks/helpers/ToplistRow/NoAccountList.svelte";
  import PaymentList from "$lib/admin/components/blocks/helpers/ToplistRow/PaymentList.svelte";
  import { PaymentMethods } from "$lib/common/enums/paymentMethods";
  import NewCasinosList from "./ToplistRow/NewCasinosList.svelte";
  import FastWithdrawalsList from "./ToplistRow/FastWithdrawalsList.svelte";
  import CasinoBonusList from "./ToplistRow/CasinoBonusList.svelte";
  import FreespinsBonusList from "./ToplistRow/FreespinsBonusList.svelte";
  import TournamentSponsor from "./ToplistRow/TournamentSponsor.svelte";

  export let props = null;
  export let operator = null;
  export let listIndex = null;
  export let showMoreCasinos = null;
  export let dataElement;
  export let toplistPosition;
  export let forceDisplay = false; //This is used to force display of a casin even if it's hidden (Review Page)

  let reviewLink =
    `/${$project.data?.categoryPages?.reviews.slug}/${operator?.name_lower}` ??
    "";
  let ctaLink =
    `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? "";

  $: {
    if (operator?.name_lower === "miljonlotteriet") {
      reviewLink = `/spela/${operator?.name_lower}`;
      ctaLink = `/spela/${operator?.name_lower}`;
    } else if ($project.settings.market === "no") {
      reviewLink = `/spela/${operator?.name_lower}/no`;
      ctaLink = `/spela/${operator?.name_lower}/no`;
    } else if (
      $page.url.pathname ===
      `/${$project.data?.categoryPages?.reviews.slug}/${operator?.name_lower}`
    ) {
      reviewLink =
        `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? "";
    } else {
      reviewLink =
        `/${$project.data?.categoryPages?.reviews.slug}/${operator?.name_lower}` ??
        "";
      ctaLink =
        `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? "";
    }
  }

  $: visibleOrNot =
    listIndex + 1 > Number(props.toplistLength) && !showMoreCasinos
      ? "none"
      : "block";

  // TODO: add a toplistRowType variable to differentiate later?
</script>

{#if operator && (!operator.rating?.[$project.key]?.[$project.settings.market]?.hide || forceDisplay) && !operator.rating?.[$project.key]?.[$project.settings.market]?.closed}
  <!-- TODO: needed this #if statement as browsing between pages which uses this component sometimes makes it crash in the unload/load period..  -->
  <!-- {#if listIndex + 1 <= Number(props.toplistLength) || showMoreCasinos} -->
  {#if props.toplistFocus === "default" || props.toplistFocus === "casino"}
    <DefaultList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "casino-bonus"}
    <CasinoBonusList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "freespins-bonus"}
    <FreespinsBonusList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "no-account"}
    <NoAccountList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "new-casinos"}
    <NewCasinosList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "fast-withdrawals"}
    <FastWithdrawalsList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "paymentmethod"}
    <PaymentList
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else if props.toplistFocus === "tournament-sponsor"}
    <TournamentSponsor
      {operator}
      {project}
      {props}
      {visibleOrNot}
      {dataElement}
      {toplistPosition}
    />
  {:else}
    <div
      class="toplist-item"
      class:hide={listIndex + 1 > Number(props.toplistLength)}
    >
      <div
        class="toplist-container"
        class:campaign={operator?.rating?.[$project.key]?.[
          $project.settings.market
        ]?.campaign}
        style={`${
          operator.visuals?.[$project.key]?.[$project.settings.market]
            ?.primary_color
            ? `border-color: rgba(${
                operator.visuals[$project.key][$project.settings.market]
                  .primary_color
              })`
            : ""
        }`}
      >
        <div
          class="toplist-img {'_' + operator['name_lower']}"
          style={`${
            operator.visuals?.[$project.key]?.[$project.settings.market]
              ?.primary_color
              ? `background-color: rgba(${
                  operator.visuals[$project.key][$project.settings.market]
                    .primary_color
                })`
              : ""
          }`}
        >
          <Ribbon {operator} />
          <a
            class="title"
            href={reviewLink}
            rel={reviewLink.includes($project.settings.redirectUrl)
              ? "nofollow noreferrer"
              : ""}
            target={reviewLink.includes($project.settings.redirectUrl)
              ? "_blank"
              : ""}
          >
            <span>{operator["name"]}</span>
          </a>
          <a class="img" href={ctaLink} rel="nofollow noreferrer">
            <img
              loading={listIndex > 5 ? "lazy" : "eager"}
              alt={operator["name"]}
              src={getOperatorLogo($project, operator.logo)}
            />
          </a>
          <Rating {operator} />
        </div>

        <!-- Operator Campaign -->
        {#if operator?.rating?.[$project.key]?.[$project.settings.market]?.campaign}
          {@const campaign =
            operator?.rating?.[$project.key]?.[$project.settings.market]
              ?.campaign}
          <div class="toplist-campaign">
            <div class="stamp">
              <Icons name="gift" strokeWidth="2" color="#fff" />
            </div>
            <div class="campaign-text">
              <p><b>{campaign.title}</b> - {campaign.description}</p>
            </div>
            <a href={campaign.link} target="_blank" rel="nofollow noreferrer"
              >{campaign.anchor}</a
            >
          </div>
        {/if}

        <!-- Toplist Bonus -->
        <div
          class={"toplist-bonus " +
            props.toplistFocus +
            " _" +
            operator["name_lower"]}
          style={"background-color: rgba(var(--_" +
            operator["name_lower"] +
            "), 0.15)"}
        >
          {#if props.toplistFocus == "sport"}
            <div class="amount">
              {#if operator?.bonus?.sports?.[$project.settings.market]?.bonus_amount}
                <span
                  ><CurrencyAmount
                    amount={operator?.bonus?.sports?.[$project.settings.market]
                      ?.bonus_amount}
                  /></span
                >
                {#if operator?.bonus?.sports?.[$project.settings.market]?.bonus_type && operator?.bonus?.sports?.[$project.settings.market]?.bonus_type != ""}
                  {operator?.bonus?.sports?.[$project.settings.market]
                    ?.bonus_type}
                {:else}
                  <Translate text="Oddsbonus" />
                {/if}
              {:else if operator?.bonus?.sports?.[$project.settings.market]?.bonus_fallback}
                {@html operator?.bonus?.sports?.[$project.settings.market]
                  ?.bonus_fallback}
              {:else}
                <span class="no-bonus"><Translate text="No" /></span>
                <Translate text="Oddsbonus" />
              {/if}
            </div>

            <div class="turnover">
              {#if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"] === "?"}
                <span class="no-bonus">?</span>
                <Translate text="Wagering req." />
              {:else if Number(operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]) === 0}
                <Translate text="No turnover" />
              {:else if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]}
                <span class="sports-turnover"
                  >{operator?.bonus?.sports?.[$project.settings.market]?.[
                    "bonus_turnover"
                  ]} x</span
                >
                <Translate text="Wagering req." />
              {/if}
            </div>

            <!-- <div class="wagering">
              <GaugeCircle />
              <span>
                Bonus <Translate text="Wagering req." lowercase={true} /></span
              >
              <span class="bonus-wagering"
                >{operator?.bonus?.sports?.[$project.settings.market]?.[
                  "bonus_turnover"
                ]
                  ? `${
                      operator?.bonus?.sports?.[$project.settings.market]?.[
                        "bonus_turnover"
                      ]
                    }x`
                  : `-`}</span
              > -->
            <!-- {#if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"] === "?"}
                <span class="no-bonus">?</span>
                <Translate text="Wagering req." />
              {:else if Number(operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]) === 0}
                <Translate text="No turnover" />
              {:else if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]}
                <span class="sports-turnover"
                  >{operator?.bonus?.sports?.[$project.settings.market]?.[
                    "bonus_turnover"
                  ]} x</span
                >
                <Translate text="Wagering req." />
              {/if} -->
            <!-- </div> -->
          {:else if props.toplistFocus == "lotto"}
            <div class="lotto">
              <Icons name="gift" strokeWidth="2" color="#2ca5e4" />
              <span
                >{operator?.bonus?.lotto?.[$project.settings.market]
                  ?.bonus}</span
              >
            </div>
          {:else}
            <div
              class="amount"
              class:no-bonus={!operator?.bonus?.casino?.[
                $project.settings.market
              ]?.bonus_amount}
            >
              {#if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount}
                <span
                  ><CurrencyAmount
                    amount={operator?.bonus?.casino?.[$project.settings.market]
                      ?.bonus_amount}
                  /></span
                >
                {operator?.bonus?.casino?.[$project.settings.market]
                  ?.bonus_type ?? "bonus"}
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.amount}
                <span
                  >{(operator?.bonus?.casino[$project.settings.market]?.currency
                    ? operator?.bonus?.casino[$project.settings.market]
                        ?.currency + " "
                    : "") +
                    operator?.bonus?.casino[$project.settings.market]
                      ?.amount}</span
                > bonus
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.bonus_fallback}
                {@html operator?.bonus?.casino?.[$project.settings.market]
                  ?.bonus_fallback}
                <!-- {@html operator?.bonus?.casino?.[$project.settings.market]?.bonus_fallback.replaceAll('<br>', ' ')} -->
              {:else}
                <span class="no-bonus"><Translate text="No" /></span> Bonus
              {/if}
            </div>

            <div
              class="freespins"
              class:no-free-spins={!operator?.bonus?.casino?.[
                $project.settings.market
              ]?.["freespins_amount"] &&
                !operator?.bonus?.casino?.[$project.settings.market]
                  ?.fallbacks?.["freespins_amount"]}
            >
              {#if operator?.bonus?.casino?.[$project.settings.market]?.["freespins_amount"]}
                <span
                  >{operator?.bonus?.casino[$project.settings.market]?.[
                    "freespins_amount"
                  ]}</span
                >
                {operator?.bonus?.casino[$project.settings.market]?.[
                  "freespins_type"
                ] === undefined ||
                operator?.bonus?.casino[$project.settings.market]?.[
                  "freespins_type"
                ] === ""
                  ? "Free spins"
                  : operator?.bonus?.casino[$project.settings.market]?.[
                      "freespins_type"
                    ]}
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.["freespins_fallback"]}
                {@html operator.bonus.casino[$project.settings.market][
                  "freespins_fallback"
                ]}
              {:else}
                <span class="no-free-spins"><Translate text="No" /></span> free spins
              {/if}
            </div>

            <!-- <div class="wagering">
              <GaugeCircle />
              <span>
                Bonus <Translate text="Wagering req." lowercase={true} /></span
              >
              <span class="bonus-wagering"
                >{operator.bonus?.casino?.[$project.settings.market]
                  ?.bonus_turnover
                  ? `${
                      operator.bonus?.casino?.[$project.settings.market]
                        ?.bonus_turnover
                    }x`
                  : `-`}</span
              >
              <BadgeDollarSign />
              <span>
                Spin <Translate text="Wagering req." lowercase={true} /></span
              >
              <span class="spin-wagering"
                >{operator.bonus?.casino?.[$project.settings.market]
                  ?.freespins_turnover != (null || undefined)
                  ? `${
                      operator.bonus?.casino?.[$project.settings.market]
                        ?.freespins_turnover
                    }x`
                  : `-`}</span
              >
            </div> -->
          {/if}

          <!-- TODO: old, these should be re-worked with the new design per ~15 Jan 2024 -->
          {#if props.toplistFocus === "mindeposit"}
            <div class="mindeposit">
              {#if operator.data.depositLimits?.min[$project.settings.market]}
                <span
                  >{operator.data?.depositLimits?.min[
                    $project.settings.market
                  ]}</span
                >
                <Translate text="Minimum deposit" />
              {/if}
            </div>
          {:else if props.toplistFocus === "freespins"}
            <div class="freespins-nodeposit">
              {#if operator.bonus?.casino?.[$project.settings.market]?.["freespins-no-deposit"]}
                <span
                  >{operator.bonus.casino[$project.settings.market][
                    "freespins-no-deposit"
                  ]}</span
                >
                <Translate text="FS no deposit" />
              {:else}
                <span><Translate text="No" /></span>
                <Translate text="FS no deposit" />
              {/if}
            </div>
          {/if}
        </div>

        <!-- Toplist Features-->
        <div
          class={"toplist-features " +
            props.toplistFocus +
            " _" +
            operator["name_lower"]}
        >
          <ul>
            <li>
              <img
                loading="lazy"
                src={$project.data?.imageFolders?.icons +
                  "spelinspektionen" +
                  ".png?h=54&q=100&auto=format"}
                alt={`${operator["name"]} har licens fra Spelinspektionen`}
              />
              <span>Licens</span>
            </li>
            <li>
              <img
                loading="lazy"
                src={$project.data?.imageFolders?.icons +
                  "bankid" +
                  ".png?h=54&q=100&auto=format"}
                alt={`${operator["name"]} har BankID`}
              />
              <span>BankID</span>
            </li>
            <li
              class:not={!operator?.data?.deposit?.[
                $project.settings.market
              ]?.includes(PaymentMethods.SWISH)}
            >
              <img
                loading="lazy"
                src={$project.data?.imageFolders?.icons +
                  "swish" +
                  ".png?h=54&q=100&auto=format"}
                alt={`${operator["name"]} har Swish`}
              />
              <span>Swish</span>
            </li>
            <li
              class:not={!operator?.data?.deposit?.[
                $project.settings.market
              ]?.includes(PaymentMethods.TRUSTLY)}
            >
              <img
                loading="lazy"
                src={$project.data?.imageFolders?.icons +
                  "trustly" +
                  ".png?h=54&q=100&auto=format"}
                alt={`${operator["name"]} har Trustly`}
              />
              <span>Trustly</span>
            </li>
          </ul>
        </div>

        <!-- Experience View -->
        {#if props.toplistFocus === "experience"}
          <div class="toplist-experience">
            <div class="name">{operator.name}</div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{translations["Wagering requirements on withdrawals"][
                  $project.settings.lang
                ]}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_min_deposit"
                ] ?? "-"}</span
              >
            </div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{`${
                  translations["Withdrawal attempt"][$project.settings.lang]
                } ${translations["One"][$project.settings.lang]}`}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_max_bet"
                ] ?? "-"}</span
              >
            </div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{`${
                  translations["Withdrawal attempt"][$project.settings.lang]
                } ${translations["Two"][$project.settings.lang]}`}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_max_bet"
                ] ?? "-"}</span
              >
            </div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{translations["Speed"][$project.settings.lang]}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_max_profit"
                ] ?? "-"}</span
              >
            </div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{translations["Response time from live chat (avg)"][
                  $project.settings.lang
                ]}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_max_profit"
                ] ?? "-"}</span
              >
            </div>
            <div class="t-p-box">
              <span class="t-p-b-desc"
                >{translations["Rank from other review sites"][
                  $project.settings.lang
                ]}</span
              >
              <span
                >{operator?.bonus?.casino[$project.settings.market]?.[
                  "bonus_max_profit"
                ] ?? "-"}</span
              >
            </div>
          </div>
        {/if}

        <ul class="toplist-pros">
          {#if operator?.rating?.[$project.key]?.[$project.settings.market]?.prosCasino && props.toplistFocus == "casino"}
            {#each operator.rating[$project.key][$project.settings.market].prosCasino as pro}
              {#if pro}
                <li>{pro}</li>
              {/if}
            {/each}
          {:else if operator?.rating?.[$project.key]?.[$project.settings.market]?.prosSports && props.toplistFocus == "sport"}
            {#each operator.rating[$project.key][$project.settings.market].prosSports as pro, i}
              {#if pro && pro != ""}
                <li>{pro}</li>
              {:else}
                <li>
                  {operator?.rating?.[$project.key]?.[$project.settings.market]
                    ?.prosCasino?.[i]}
                </li>
              {/if}
            {/each}
          {/if}
          {#if operator.licenses?.includes($project?.data?.mainLicense)}
            <li class="license">
              <Translate
                text="Casino has english license"
                lang={$project?.settings?.market}
              />
            </li>
          {:else if operator.countries?.includes($project?.settings?.market)}
            <li class="license open">
              <Translate
                text="Casino open for english players"
                lang={$project?.settings?.market}
              />
            </li>
          {/if}
        </ul>

        <div class="toplist-cta">
          <a
            href={`/${$project.settings.redirectUrl}/${operator.name_lower}`}
            target="_blank"
            rel="nofollow noreferrer noopener"
            class="visit"
          >
            <span
              ><Translate
                text={"Visit" +
                  (props.toplistFocus == "casino" ? " casino" : "")}
              /></span
            >
            <span><Translate text="Proceed to" /> {operator["name"]}</span>
          </a>
          {#if $page.url.pathname != `/${$project.data?.categoryPages?.reviews.slug}/${operator.name_lower}` && reviewLink}
            <a href={reviewLink} class="review">{operator["name"]} </a>
          {/if}
        </div>
        <div class="toplist-terms">
          <Terms {operator} />
        </div>
      </div>
    </div>
  {/if}
  <!-- {/if} -->
{/if}
